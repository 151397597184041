let instance;

/**
 * @description This class is a singleton. Holds functionality to track and modify a single scheduled task.
 */
export class Scheduler {

  constructor() {
    if (!!instance) {
      return instance;
    }
    this.timerId;
    this.currentRefreshAt;
    instance = this;
    return this;
  }

  static getInstance() {
    if (!!instance) {
      return instance;
    }
    return new Scheduler();
  }

  /**
   * 
   * @param       {function} task
   *              Function to be executed
   * @param       {number} refreshAt
   *              The absolute time in ms when the data shall expire
   * @param       {number} buffer 
   *              Time value in ms to be used as a buffer in multiple scenarios. This value is subtracted from the refreshAt value when scheduling the refresh.
   *              Buffer is also used when comparing a scheduled request with current time. If the delta is less than buffer value the event is immediately fired.
   * @description Schedules a task. Only 1 task can be scheduled at a time. Used for refreshing cache data. Currently no suport for function arguments to task.
   *              If an refreshAt value is handed in that is in the past, no action is taken to prevent a loop. 
   */
  scheduleTaskByExpiration(task, refreshAt) {
    const currentTime = new Date().getTime();
    
    if(refreshAt === undefined || refreshAt === null) {
      return;
    }
    
    if(refreshAt <= currentTime) {
      // We may have purposefully set an expiration time in the past. Do not schedule a refresh or a viscious constant refresh will occur.
      return;
    }

    if (this.timerId && this.refreshAt) {
      if (this.currentRefreshAt < refreshAt && this.currentRefreshAt > currentTime) {
        // A task is already scheduled sooner than the requested one
        return;
      } else {
        this.cancel();
      }
    }

    let duration = refreshAt - currentTime;

    this.timerId = setTimeout(task, duration);
    this.currentRefreshAt = refreshAt;
  }

  /**
   * @description Cancel the current scheduled task if one exists.
   */
  cancel() {
    if (this.timerId) {
      clearTimeout(this.timerId);
      this.timerId = undefined;
      this.currentRefreshAt = undefined;
    }
  }
}