import { ManifestClient } from './manifest';

let initializedResolve;
let hasInitialized = false;
let initializedPayload;

let initialized = new Promise((resolve) => {
    initializedResolve = resolve;
})

export const Environments = {
    STAGING: 'staging',
    PRODUCTION: 'production'
}

export const Events = {
    MANIFEST_UPDATE: 'mi-manifest-update'
}

export const RefreshEventReasons = {
    USER_REQUESTED: 'user requested',
    DATA_EXPIRED: 'data expired',
    INTERNAL_REFRESH: 'internal refresh',
    RE_INITIALIZATION: 're-initialization'
}

export class Configuration {
    static getConfiguration(env) {
        if(env === Environments.STAGING) {
            return {
                baseUri: 'https://staging.meltwater.net/manifest',
                cookieDomain: '.meltwater.net',
                resetTokenUrl: 'https://live.staging.gaf-identity-provider.meltwater.io/auth/resetToken',
                logoutUrl: 'https://live.staging.gaf-identity-provider.meltwater.io/logout'
            }
        } else if (env === Environments.PRODUCTION) {
            return {
              baseUri: "https://app.meltwater.com/manifest",
              cookieDomain: ".meltwater.com",
              resetTokenUrl: "https://app.meltwater.com/auth/resetToken",
              logoutUrl: "https://app.meltwater.com/logout",
            };
        } else {
            throw new Error(`Configuration - unknown environment provided. Provided Value ${env}`);
        }
    }
}

export class Initializer {
    static initialize(env, payload) {
        if (!hasInitialized) {
            new ManifestClient(Configuration.getConfiguration(env), payload);
            initializedResolve();
            hasInitialized = true;
        } else if (hasInitialized && JSON.stringify(initializedPayload) !== JSON.stringify(payload)) {
            // If the payload has changed, we have to re-request manifest data before the client app can use us.
            ManifestClient.getInstance().then(instance => {
                instance.payload = payload;
                instance.__refreshData(RefreshEventReasons.RE_INITIALIZATION);
            });
        }
    }
    static onInitialized() {
        return initialized;
    }
}