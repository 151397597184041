export class NavItemUtils {

  static hydrateNavItems(navigation) {
    if (navigation && navigation.length) {
      let navItems = navigation.map(item => {
        item.isActive = false
        item.iconSvg = item.icon.indexOf('::') !== -1 ? item.icon.split('::')[1] : item.icon.split(':')[1]
        return item
      })
      this.setActiveNavItem(navItems);
      return navItems;
    }
    return [];
  }

  static setActiveNavItem(items) {
    items.map((item, index, array) => {
      array[index].isActive = this.checkIsLinkActive(item);

      if (item.subItems) {
        item.subItems.map(subItem => {
          let isSubLinkActive = this.checkIsLinkActive(subItem);
          subItem.isActive = isSubLinkActive
        })
      }
    })

    if (items.find((element) => {
      if (element.isActive === true) return true;
      if (element.subItems && (element.subItems.find((sub) => {
        return sub.isActive;
      }) === undefined)) {
        return true;
      }
      return false
    }) === undefined) {
      console.log('mi-manifest-client could not match the current url to any navigation item.');
      console.log('Contact the owner of @meltwater/mi-manifest-client for help debugging or adding your url to match a navigation item.');
    }
  }

  static getActiveAppId(navigation) {
    NavItemUtils.setActiveNavItem(navigation);
    let appId = null;
    navigation.forEach(item => {
      if (appId) {
        return;
      }

      if (item.subItems) {
        const matchedItem = item.subItems.find(i => i.isActive);
        if (matchedItem) {
          appId = matchedItem.appId;
        }
      }
      
      if (item.isActive && !appId) {
        appId = item.appId;
        return;
      }

    });
    return appId;
  }

  static checkIsLinkActive(item) {
    let currentPath = window.location.pathname;
    let currentUrl = window.location.origin + window.location.pathname;
    
    let prefixedMatcher = item.useMiCore ? '/a' + item.matcher : item.matcher;

    switch (item.matcherType) {
      case 'exact':
        return (item.matcher === currentPath || item.matcher === currentUrl || prefixedMatcher == currentPath)
      case 'start':
        return currentPath.indexOf(item.matcher) === 0 || currentPath.indexOf(prefixedMatcher) === 0;
      case 'hostname':
        return window.location.hostname.indexOf(item.matcher) !== -1
      case 'regex':
        return new RegExp(item.matcher).test(currentUrl)
      default:
        console.log(`Unknown location matcher encountered in manifest client: ${item}`)
        return false
    }
  }

  // Returns the navigation structure with added 'group' and 'positionInGroup' properties
  static mapNavigationResponseV2(navigation, navigationGroups) {
    return navigation.map(item => {
      navigationGroups.forEach((group, index) => {
        const groupIndex = group.indexOf(item.id)

        if (groupIndex !== -1) {
          item.group = index
          item.positionInGroup = groupIndex
        }
      })
      if (item.group === undefined) {
        item.group = navigationGroups.length - 1;
        item.positionInGroup = 99
      }
      return item
    })
  }
  
}