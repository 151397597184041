import { getCookie } from './cookies';
import { NavItemUtils } from './nav-item-utils';
import { ManifestNavigation } from './manifest-navigation';
import { AppContainerState, AppContainerStateChangeName } from './app-container-state';
import { Events, RefreshEventReasons } from './initializer';
import { Scheduler } from './scheduler';
import { RollingSessions } from "./rolling-sessions";
import jwt from 'jwt-decode';

export const localStorageKey = 'manifest_client_cache';
export const sessionStorageUrlTargetKey = 'manifest_client_url_target';


let instanceResolve;
let instance = new Promise(resolve => {
    instanceResolve = resolve;
});

export class ManifestClient {
    constructor({ baseUri, resetTokenUrl, cookieDomain, logoutUrl }, payload) {
        this.payload = payload
        this.baseUri = baseUri;
        this.resetTokenUrl = resetTokenUrl;
        this.cookieDomain = cookieDomain;
        this.logoutUrl = logoutUrl;
        this.manifestPromise = null;
        this.token = this.getAuthToken('gydaToken');
        this.isInitialFetch = true;
        this.currentAppId = null;

        // If a payload was provided by the end user then the saved cache is invalid
        if(payload) {
            this.clearManifestCache();
        }

        // Add sub-classes
        this.navigation = new ManifestNavigation(this);
        this.rollingSessions = new RollingSessions({ resetTokenUrl, cookieDomain, logoutUrl });

        instanceResolve(this);
    }

    async getCurrentManifest() {
        if (!this.manifestPromise) {
            if (window.location.pathname.indexOf('/switchingCompany') !== -1 ||
                window.location.pathname === '/reloadApplication') {
                this.clearManifestCache();
                this.manifestPromise = new Promise((resolve, reject) => { });
                return this.manifestPromise;
            }

            const isUserNavigation = !!(PerformanceNavigation &&
                PerformanceNavigation.TYPE_RELOAD &&
                performance &&
                performance.navigation &&
                performance.navigation.type === PerformanceNavigation.TYPE_NAVIGATE);

            let cachedManifest = this.getCachedManifest();

            if (isUserNavigation && 
                cachedManifest && 
                this.validateCacheByUserAndCompanyId(cachedManifest) &&
                this.validateUrlTarget()
                ) {

                console.info('Using cached Manifest Data');

                if(this.isInitialFetch === true) {
                    this.__refreshData(RefreshEventReasons.INTERNAL_REFRESH);
                }

                return cachedManifest;
            }

            let fetchRequest = {
                url: this.baseUri,
                options: {
                    headers: {
                        Authorization: this.token
                    }
                }
            }

            if (this.payload) {
                fetchRequest.options = {
                    ...fetchRequest.options,
                    body: JSON.stringify(this.payload),
                    method: 'POST'
                }
            }

            this.manifestPromise = fetch(fetchRequest.url, fetchRequest.options)
                .then(async response => {
                    if (!response.ok) {
                        throw new Error(`Manifest - bad response from manifest endpoint`);
                    }
                    let manifest = await response.json();
                    this.cacheManifest(manifest);
                    return this.__supplementManifest(manifest);
                })
                .catch((error) => {
                    this.manifestPromise = null;
                    throw new Error(`Manifest - Failed to fetch manifest, ${error}`);
                });
        }

        return this.manifestPromise.then(manifest => this.__supplementManifest(manifest));
    }

    cacheManifest(manifest) {
        if (manifest.metadata.cacheTtl === undefined ||
            manifest.metadata.cacheTtl.expiresAt === undefined) {
                console.log(`No cache or no expiration on manifest data. Caching aborted.`);
                return;
        }

        try {
            localStorage.setItem(localStorageKey, JSON.stringify(manifest));

            const scheduler = Scheduler.getInstance();
            scheduler.scheduleTaskByExpiration(() => {this.scheduledRefresh()}, manifest.metadata.cacheTtl.refreshAt);

        } catch (e) {
            console.log(`Unable to set manifest data in localStorage`, e);
        }
    }

    getCachedManifest() {
        let cachedManifest;

        try {
            cachedManifest = JSON.parse(localStorage.getItem(localStorageKey));
        } catch (e) {
            console.log(`Unable to retrieve cached manifest data from localStorage`, e);
            return undefined;
        }

        if (!cachedManifest ||
            !cachedManifest.metadata ||
            !cachedManifest.metadata.cacheTtl ||
            cachedManifest.metadata.cacheTtl.expiresAt < new Date().getTime()) {
            return undefined
        }

        return this.__supplementManifest(cachedManifest);
    }

    validateCacheByUserAndCompanyId(cachedManifest) {
        try {
            const decoded = jwt(this.token);
            if (!decoded || !decoded.user) {
                throw new Error('Decoded JWT or user does not exist.');
            }
            if ((cachedManifest.user._id === decoded.user._id) &&
                (cachedManifest.user.activeCompanyId === decoded.user.activeCompanyId)) {
                return true;
            }
        } catch (ex) {
            console.log(`Error decoding jwt token in Manifest Client, or user / company id not available.`, ex);
        }
        return false;
    }

    validateUrlTarget() {
        if(this.isInitialFetch === false) {
            return true;
        }

        let data = sessionStorage.getItem(sessionStorageUrlTargetKey);
        sessionStorage.removeItem(sessionStorageUrlTargetKey);

        if(data !== undefined && data !== null && (window.location.href === data || window.location.pathname === data)) {
            return true;
        }
        return false;
    }

    clearManifestCache() {
        localStorage.removeItem(localStorageKey);
        sessionStorage.removeItem(sessionStorageUrlTargetKey);
    }

    async refreshData() {
        return this.__refreshData(RefreshEventReasons.USER_REQUESTED);
    }

    scheduledRefresh() {
        this.__refreshData(RefreshEventReasons.DATA_EXPIRED);
    }

    async setNavigationAndTitleState(navigation) {
        let navItemsState = [];
        let title, subTitle;

        // Do not override the existing nav items if navItems length is 0
        // to avoid white flicker of nav items
        if (!navigation.length) {
            return;
        }

        for (let i = 0; i < navigation.length; i++) {
            let newNavItem = {
                icon: navigation[i].icon,
                isActive: navigation[i].isActive,
                pos: [navigation[i].group, navigation[i].positionInGroup]
            }

            navItemsState.push(newNavItem)

            // Also look for title and subtitle
            if (navigation[i].isActive) {
                title = navigation[i].translatedTitle;
            }
            if (navigation[i].subItems) {
                for (let j = 0; j < navigation[i].subItems.length; j++) {
                    if (navigation[i].subItems[j].isActive) {
                        title = navigation[i].translatedTitle;
                        subTitle = navigation[i].subItems[j].translatedTitle;
                    }
                }
            }
        }

        let appContainerState = new AppContainerState();
        appContainerState.updateAppContainerState(AppContainerStateChangeName.navigation, navItemsState)
        appContainerState.updateAppContainerState(AppContainerStateChangeName.pageName, title);
        appContainerState.updateAppContainerState(AppContainerStateChangeName.pageSubName, subTitle);
    }

    getAuthToken() {
        const tokenCookie = getCookie('gydaToken');

        try {
            const token = JSON.parse(tokenCookie).token;
            return token;
        } catch (e) {
            console.log('Failed getting token from cookie');
            return null;
        }
    }

    __supplementManifest(manifest) {
        manifest.navigation = NavItemUtils.hydrateNavItems(manifest.navigation);
        manifest.navigation = NavItemUtils.mapNavigationResponseV2(manifest.navigation, manifest.navigationGroups)
        this.setNavigationAndTitleState(manifest.navigation);
        if (this.currentAppId === null) {
            this.currentAppId = NavItemUtils.getActiveAppId(manifest.navigation);
        }
        return manifest;
    }

    async __triggerEvent(eventType, reason) {
        const data = {
            timestamp: new Date(),
            detail: {
                reason: reason
            }
        };

        if (typeof CustomEvent === 'function') {
            // For sane modern browsers that support features released in the last decade
            let manifestUpdatedEvt = new CustomEvent(eventType, data);
            window.dispatchEvent(manifestUpdatedEvt);
        } else {
            // For certain other browsers...
            let manifestUpdatedEvt = document.createEvent('CustomEvent');
            manifestUpdatedEvt.initCustomEvent(eventType, true, true, data);
            window.dispatchEvent(manifestUpdatedEvt);
        }

    }

    // Triggers an update to the manifest data, firing an event with the reason.
    async __refreshData(reason) {
        this.isInitialFetch = false;
        this.manifestPromise = null;
        this.clearManifestCache();
        let newManifestPromise = this.getCurrentManifest();

        // Fire off the event letting everyone know new data is coming
        this.__triggerEvent(Events.MANIFEST_UPDATE, reason);

        return newManifestPromise;
    }

    static getInstance() {
        return instance;
    }

    setSessionVerificationUrl(requestedUrl) {
        sessionStorage.setItem(sessionStorageUrlTargetKey, requestedUrl);
    }
}
