import { NavItemUtils } from './nav-item-utils';

export class ManifestNavigation {
    
    constructor(manifestInstance) {
        this.manifestInstance = manifestInstance;
    }
    
    async switchApp() {
        const manifest = await this.manifestInstance.getCurrentManifest();
        
        const newAppId = NavItemUtils.getActiveAppId(manifest.navigation);

        if (window.location.search.indexOf('unknownRoute=true') !== -1) {
            console.debug('Route already has unknownRoute in it; not continuing navigation');
            return;
        }

        if (this.manifestInstance.currentAppId !== newAppId) {
            window.location.reload();
        } else {
            if (window.location.search) {
                window.location.search += '&unknownRoute=true';
            } else {
                window.location.search = '?unknownRoute=true';
            }
        }
    }

}